import './Home.css';
import Navapp from './Navapp';
import LoggedInNav from './LoggedInNav';
import Footer from './Footer';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Container, Modal, Button, Card, Col, Form } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";

function AnimalFacts(props){
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [show, setShow] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [animalFacts, setAnimalFacts] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    const verifyToken = () =>{
        axios.get('/http://localhost:5000/verifyToken', { withCredentials: true })
        .then(function (response) {
            /* ONLY RUNS IF SUCCESS, NOT EVEN WHEN CODE 404 */
            setIsLoggedIn(true)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const handleLogin = () =>{
        axios.post('/http://localhost:5000/login', {
            username: username,
            password: password
        })
        .then(function (response) {
            /* ONLY RUNS IF SUCCESS, NOT EVEN WHEN CODE 404 */
            navigate('/')
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const fetchAnimalFacts = () =>{
        axios.get('/http://localhost:5000/fetchAnimalFacts')
        .then(function (response) {
            /* ONLY RUNS IF SUCCESS, NOT EVEN WHEN CODE 404 */
            if (response.status === 200){
                setAnimalFacts(response.data);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(()=>{verifyToken(); fetchAnimalFacts()}, [])

    return(
        <>
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" onChange={(e)=>{setUsername(e.target.value)}} value={username} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Kata Sandi</Form.Label>
                                <Form.Control type="password" onChange={(e)=>{setPassword(e.target.value)}} value={password} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Ingat Saya" />
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={handleLogin}>
                                Masuk
                            </Button>
                </Form>
                <p className='mt-3'>Belum daftar? <Link to='/register' className='text-decoration-none'>Buat akun baru</Link></p>
            </Modal.Body>
            </Modal>

            {isLoggedIn? <LoggedInNav /> : <Navapp />}
            <div className='bg-warning'>
                <br />
                <Container>
                <Link to='/' className='text-decoration-none'><Button variant='primary'><i className="bi bi-arrow-left-short"></i>Kembali</Button></Link>
                <h3 className='mt-3'>Fakta-Fakta Binatang</h3>
                <Row xs={1} md={5} className="g-4">
                    {animalFacts.map((item, idx) => (
                            <Col key={idx}>
                            <Card>
                                <Card.Img variant="top" src={require(`./${item.image}.jpg`)} width={200} height={200} />
                                <Card.Body className='bg-black text-white'>
                                <Card.Title>{item.title}</Card.Title>
                                <Button variant="primary" onClick={()=>{if (isLoggedIn){navigate(`/fakta-binatang/${item.link_name}`)} else{handleShow()}}}>Mulai!</Button>
                                </Card.Body>
                            </Card>
                            </Col>
                    ))}
                </Row><br/><br/>
                </Container><br/><br/>
        </div>
        <Footer />
        </>
    );
}

export default AnimalFacts;